import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './OrdersPage.css';
import Footer from '../components/Footer'; // ✅ Footer imported
import { generateTicketPDF } from '../generateTicket'; // ✅ Import ticket PDF generator
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaCheck } from 'react-icons/fa';

const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract Base64
  });
};

const fetchEventLocations = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'events'));
    const locations = {};

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      locations[data.name] = {
        location: data.location || 'Venue TBD',
        datetime: data.datetime || 'Date & Time TBD',
      };
    });

    return locations; // ✅ Return correct event locations
  } catch (error) {
    console.error('Error fetching event locations:', error);
    return {};
  }
};

const resendEmail = async (order) => {
  try {
    console.log(`Resending email to ${order.email}...`);

    // ✅ Fetch event locations before generating emails
    const eventLocationsData = await fetchEventLocations();

    // ✅ Generate PDFs for each ticket
    const ticketPDFs = await Promise.all(
      order.tickets.map(async (ticket, index) => {
        // ✅ Retrieve correct event location and datetime
        const eventLocation =
          eventLocationsData[ticket.eventName]?.location || 'Venue TBD';
        const datetime =
          eventLocationsData[ticket.eventName]?.datetime || 'Date & Time TBD';

        const ticketDetails = {
          orderNumber: order.orderNumber,
          customerName: order.name,
          eventName: ticket.eventName,
          eventLocation, // ✅ Correctly assigned
          datetime, // ✅ Correctly assigned
          ticketNumber:
            ticket.ticketNumber ?? `${order.orderNumber}-${index + 1}`, // ✅ Provide fallback
          ticketType: ticket.ticketType,
        };

        console.log('Generated Ticket Details:', ticketDetails); // ✅ Debugging Log

        const pdfBlob = await generateTicketPDF(ticketDetails);
        const pdfBase64 = await blobToBase64(pdfBlob);

        return {
          filename: `BCT_${ticketDetails.ticketNumber}_RESEND.pdf`,
          content: pdfBase64,
          encoding: 'base64',
        };
      })
    );

    // ✅ Send email request to the Cloud Function
    const response = await fetch(
      'https://us-central1-black-canyon-tickets-bct.cloudfunctions.net/sendOrderConfirmation',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: order.name,
          email: order.email,
          orderNumber: order.orderNumber,
          total: order.total,
          phone: order.phone,
          address: order.address,
          tickets: order.tickets.map((ticket, index) => ({
            eventName: ticket.eventName,
            eventLocation:
              eventLocationsData[ticket.eventName]?.location || 'Venue TBD',
            datetime:
              eventLocationsData[ticket.eventName]?.datetime ||
              'Date & Time TBD',
            ticketNumber:
              ticket.ticketNumber ?? `${order.orderNumber}-${index + 1}`, // ✅ Provide fallback
            ticketType: ticket.ticketType,
          })),
          attachments: ticketPDFs, // ✅ Attach generated PDFs
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to resend email');
    }

    alert(`Order confirmation email resent to ${order.email}`);
  } catch (error) {
    console.error('Error resending email:', error);
    alert('Failed to resend email. Try again.');
  }
};

function OrdersPage() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState('All Events');
  const [editableEmails, setEditableEmails] = useState({}); // Tracks editable email values
  const [isEditing, setIsEditing] = useState({}); // Tracks which email is being edited
  const [isSaving, setIsSaving] = useState(false); // Tracks saving status

  // Enable editing for a specific order's email
  const enableEditing = (orderId) => {
    setIsEditing((prev) => ({
      ...prev,
      [orderId]: true,
    }));
    setEditableEmails((prev) => ({
      ...prev,
      [orderId]: orders.find((order) => order.id === orderId).email,
    }));
  };

  // Save the updated email to Firestore
  const saveEmail = async (orderId) => {
    const newEmail = editableEmails[orderId];
    if (!newEmail || !newEmail.includes('@')) {
      alert('Please enter a valid email address.');
      return;
    }

    setIsSaving(true);
    try {
      // Update the email in Firestore
      await updateDoc(doc(db, 'orders', orderId), { email: newEmail });

      // Update the local state to reflect the change
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, email: newEmail } : order
        )
      );

      // Disable editing mode for this order
      setIsEditing((prev) => ({
        ...prev,
        [orderId]: false,
      }));

      alert('Email updated successfully!');
    } catch (error) {
      console.error('Error updating email:', error);
      alert('Failed to update email. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  // Handle email input changes
  const handleEmailChange = (orderId, newEmail) => {
    setEditableEmails((prev) => ({
      ...prev,
      [orderId]: newEmail,
    }));
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'orders'));
        const orderList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(orderList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const openVisualization = () => {
    navigate('/admin/orders-visuals', { state: { orders } });
  };

  const eventNames = [
    ...new Set(
      orders
        .flatMap((order) =>
          Array.isArray(order.tickets)
            ? order.tickets.map((ticket) => ticket.eventName)
            : []
        )
        .filter(Boolean)
    ),
  ];

  if (loading) {
    return <div className="loading">Loading orders...</div>;
  }

  const filteredOrders = orders.filter(
    (order) =>
      selectedEvent === 'All Events' ||
      (Array.isArray(order.tickets) &&
        order.tickets.some((ticket) => ticket.eventName === selectedEvent))
  );

  const totalTickets = filteredOrders.reduce((total, order) => {
    return (
      total +
      (Array.isArray(order.tickets)
        ? order.tickets.reduce(
            (sum, ticket) => sum + (Number(ticket.quantity) || 1),
            0
          )
        : 0)
    );
  }, 0);

  const totalAmount = filteredOrders.reduce(
    (sum, order) => sum + (order.total || 0),
    0
  );

  const exportOrdersToCSV = () => {
    // Create an array where each ticket gets its own row
    const csvData = filteredOrders.flatMap((order) => {
      const baseOrderInfo = {
        OrderNumber: order.orderNumber,
        Name: order.name,
        Email: order.email,
        Phone: order.phone || 'N/A',
        Address: `${order.address?.line1 || ''}, ${
          order.address?.city || ''
        }, ${order.address?.state || ''}, ${
          order.address?.postal_code || ''
        }, ${order.address?.country || ''}`,
        OrderDate: order.createdAt
          ? typeof order.createdAt === 'string'
            ? order.createdAt
            : order.createdAt.toDate().toLocaleString()
          : 'N/A',
        OrderTotal: order.total,
      };

      if (!Array.isArray(order.tickets) || order.tickets.length === 0) {
        return [
          {
            ...baseOrderInfo,
            EventName: 'No tickets',
            TicketType: '',
            Quantity: '',
            TicketNumber: '',
            TicketPrice: '',
          },
        ];
      }

      return order.tickets.map((ticket, index) => ({
        ...baseOrderInfo,
        EventName: ticket.eventName || 'N/A',
        TicketType: ticket.ticketType || 'N/A',
        Quantity: ticket.quantity || 1,
        TicketNumber:
          ticket.ticketNumber || `${order.orderNumber}-${index + 1}`,
        TicketPrice:
          ticket.price ||
          (ticket.ticketType?.includes('VIP') ? '75.00' : '50.00'),
      }));
    });

    // Convert JSON data to CSV format
    const csv = Papa.unparse(csvData, {
      header: true,
      columns: [
        'OrderNumber',
        'Name',
        'Email',
        'Phone',
        'Address',
        'OrderDate',
        'OrderTotal',
        'EventName',
        'TicketType',
        'Quantity',
        'TicketNumber',
        'TicketPrice',
      ],
    });

    // Create a downloadable CSV file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Detailed_Orders_Report.csv';
    link.click();
  };

  return (
    <div className="orders-container">
      <h1 className="orders-heading">Orders List</h1>

      <div className="filter-container">
        <div className="order-count">
          Total Orders: {filteredOrders.length}
          <div className="total-amount">
            Total Amount: ${totalAmount.toFixed(2)}
          </div>
          <div className="total-tickets">Total Tickets: {totalTickets}</div>
        </div>

        <select
          className="event-filter"
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
        >
          <option value="All Events">All Events</option>
          {eventNames.map((eventName, index) => (
            <option key={index} value={eventName}>
              {eventName}
            </option>
          ))}
        </select>
        <button className="download-csv-btn" onClick={exportOrdersToCSV}>
          Download CSV
        </button>
        <button className="visualize-btn" onClick={openVisualization}>
          View Analytics
        </button>
      </div>

      <div className="orders-table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Total ($)</th>
              <th>Tickets</th>
              <th>Order Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders
              .filter(
                (order) =>
                  selectedEvent === 'All Events' ||
                  (Array.isArray(order.tickets) &&
                    order.tickets.some(
                      (ticket) => ticket.eventName === selectedEvent
                    ))
              )
              .map((order) => (
                <tr key={order.id}>
                  <td>{order.orderNumber}</td>
                  <td>{order.name}</td>
                  <td>
                    {isEditing[order.id] ? (
                      <div className="editable-email-container">
                        <input
                          type="email"
                          value={editableEmails[order.id] || order.email}
                          onChange={(e) =>
                            handleEmailChange(order.id, e.target.value)
                          }
                          className="editable-email"
                          disabled={isSaving}
                        />
                        <button
                          className="save-email-btn"
                          onClick={() => saveEmail(order.id)}
                          disabled={isSaving}
                        >
                          <FaCheck /> {/* Checkmark icon */}
                        </button>
                      </div>
                    ) : (
                      <div className="email-display-container">
                        <span>{order.email}</span>
                        <button
                          className="edit-email-btn"
                          onClick={() => enableEditing(order.id)}
                          disabled={isSaving}
                        >
                          <FaEdit /> {/* Pencil icon */}
                        </button>
                      </div>
                    )}
                  </td>
                  <td>{order.phone || 'N/A'}</td>
                  <td>
                    {order.address?.line1}, {order.address?.city},{' '}
                    {order.address?.state}, {order.address?.postal_code},{' '}
                    {order.address?.country}
                  </td>
                  <td>${Number(order.total).toFixed(2)}</td>
                  <td>
                    {Array.isArray(order.tickets) &&
                    order.tickets.length > 0 ? (
                      <ul className="ticket-list">
                        {order.tickets.map((ticket, index) => (
                          <li key={index}>
                            {ticket.eventName} ({ticket.ticketType}) - x
                            {ticket.quantity ?? 1} [
                            {ticket.ticketNumber ||
                              `${order.orderNumber}-${index + 1}`}
                            ]
                          </li>
                        ))}
                      </ul>
                    ) : (
                      'No tickets'
                    )}
                  </td>

                  <td>{order.createdAt || 'N/A'}</td>
                  <td>
                    <button
                      className="resend-email-btn"
                      onClick={() => resendEmail(order)}
                    >
                      Resend Email
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Footer />
    </div>
  );
}

export default OrdersPage;
